import moment from "moment";

export const checkHoliday = (date: Date, allHoliday: []) => {
  let holidayDate = false;
  let category = "Weekend";
  let name = "";

  allHoliday.forEach((holiday) => {
    if (
      moment(holiday["date"]).format("DD-MMMM-YYYY") ===
      moment(date.toDateString()).format("DD-MMMM-YYYY")
    ) {
      holidayDate = true;
      category = holiday["category"];
      name = holiday["name"];
    }
  });
  return { holidayDate, category, name };
};
