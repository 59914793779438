import React from "react";
import {
  Box,
  Card,
  Center,
  Divider,
  Group,
  Indicator,
  Text,
  createStyles,
} from "@mantine/core";

const GridView: React.FC<TGridView> = ({ data }) => {
  const { classes } = useStyles();

  const getStatusStyle = () => {

    if(data["blocked"]){
      return {
        color: "#FF0000",
        cardStatus: `${classes.blocked}`,
      };
    }
    return {
      color: "#008080",
      cardStatus: `${classes.unblocked}`,
    };
  
  };

  return (
    <Card
      shadow="md"
      radius="md"
      className={`${classes.card} ${getStatusStyle().cardStatus}`}
      padding="lg"
    >
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="md" fw={600} color="dark">
          {data["name"]}
        </Text>
        <Box className={`${classes.statusBox}`}>
          <Center>
            <Indicator
              inline
              size={8}
              position="middle-start"
              color={getStatusStyle().color}
            >
              <Text ml={10} size="xs" weight={500}>
                {data["rollNumber"]}
              </Text>
            </Indicator>
          </Center>
        </Box>
      </Group>

      <Divider my="sm" />
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="sm" fw={400} c="dimmed">
          Email:
        </Text>

        <Text ml={10} size="xs" weight={500}>
          {data["email"]}
        </Text>
      </Group>
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="sm" fw={400} c="dimmed">
          Mobile:
        </Text>

        <Text ml={10} size="xs" weight={500}>
          {data["mobile"]}
        </Text>
      </Group>
    </Card>
  );
};

const useStyles = createStyles((theme) => ({
  card: {
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: "4px",
    borderRadius: 0,
  },
  unblocked: {
    borderColor: "#008080",
  },
  blocked: {
    borderColor: "#FF0000",
  },
  statusBox: {
    paddingBlock: "0.3rem",
    paddingInline: "0.7rem",
    borderRadius: "0.4rem",
  },
  statusBoxOnTime: {
    backgroundColor: "#eff8ff",
    color: "#53b1fd",
  },
  statusBoxLate: {
    backgroundColor: "#fffaeb",
    color: "#f79009",
  },
  statusBoxAbsent: {
    backgroundColor: "#FFE5E5",
    color: "#FF0000",
  },
  statusBoxInitiate: {
    backgroundColor: "#94ffff21",
    color: "#008080",
  },
  absentDimmed: {
    visibility: "hidden",
  },
}));

export default GridView;
