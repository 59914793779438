import {
  Flex,
  Title,
  Space,
  Card,
  ScrollArea,
  ActionIcon,
  Text,
} from "@mantine/core";
import RenderDay from "./component/RenderDay";
import { Calendar } from "@mantine/dates";
import React, { useMemo, useState } from "react";
import DateTile from "./component/DateTile";
import { IconPlus } from "@tabler/icons-react";
import { useGetUpcomingHoliday } from "../../hooks/holiday/query/getUpcomingHoliday.query";
import { useGetAllHoliday } from "../../hooks/holiday/query/getAllHoliday.query";
import HolidayModal from "./modal/HolidayModal";
import { useDisclosure } from "@mantine/hooks";
import ThemeLoader from "../../component/loader/ThemeLoader";
// rebuild
const Holiday = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [defaultDate, setDefaultDate] = useState<Date>(new Date());
  const todayDate = useMemo(() => new Date(), []);
  const [fetchDate, setFetchDate] = useState<Date>(todayDate);
  const {
    refetch: refetchUpcomingHoliday,
    isRefetching: refetchingUpcomingHoliday,
    data: upcomingHoliday,
    isLoading: isUpcomingHolidayLoading,
  } = useGetUpcomingHoliday(fetchDate);
  const {
    refetch: refetchAllHoliday,
    isRefetching: refetchingAllHoliday,
    data: allHoliday,
    isLoading: isAllHolidayLoading,
  } = useGetAllHoliday();

  const refetch = () => {
    refetchAllHoliday();
    refetchUpcomingHoliday();
  };

  return (
    <Flex h="100%" direction="column" justify="center" align="space-around">
      <Flex direction="row" justify="space-around" align="center" wrap="wrap">
        <Card shadow="lg" radius="lg" withBorder mah={500} m={15}>
          {!isAllHolidayLoading && !refetchingAllHoliday ? (
            <Calendar
              defaultDate={defaultDate}
              size="xl"
              color="red"
              hideOutsideDates
              weekendDays={[]}
              renderDay={(date) => RenderDay(date, allHoliday?.data, refetch)}
              static
              onNextMonth={(date) => {
                setDefaultDate(date);
                setFetchDate(date);
              }}
              onPreviousMonth={(date) => {
                setDefaultDate(date);
                setFetchDate(date);
              }}
              onMonthSelect={(date) => {
                setDefaultDate(date);
                setFetchDate(date);
              }}
            />
          ) : (
            <ThemeLoader loading={isAllHolidayLoading} />
          )}
        </Card>

        <Card
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          miw={450}
          mah={410}
        >
          <Flex direction="column" align="center" justify="center">
            <Title order={2} weight={400} c="gray">
              NON-WORKING DAYS
            </Title>
            <ScrollArea w="100%" h={380} scrollbarSize={5} py={20}>
              {!isUpcomingHolidayLoading && !refetchingUpcomingHoliday ? (
                (upcomingHoliday?.data as []).length === 0 ? (
                  <Flex
                    mt="xl"
                    w="100%"
                    h="400"
                    align="center"
                    justify="center"
                    direction="column" // Ensures content is centered in both axis
                  >
                    <Text>No Holidays this month.</Text>
                  </Flex>
                ) : (
                  <>
                    {(upcomingHoliday?.data as []).map((holiday) => {
                      if (new Date(holiday["date"]) >= todayDate) {
                        return (
                          <DateTile
                            date={holiday["date"]}
                            category={holiday["category"]}
                            name={holiday["name"]}
                            key={holiday["date"]}
                          />
                        );
                      }
                    })}
                    {(upcomingHoliday?.data as []).map((holiday) => {
                      if (new Date(holiday["date"]) < todayDate) {
                        return (
                          <DateTile
                            date={holiday["date"]}
                            category={holiday["category"]}
                            name={holiday["name"]}
                            key={holiday["date"]}
                          />
                        );
                      }
                    })}
                  </>
                )
              ) : (
                <ThemeLoader loading={isUpcomingHolidayLoading} />
              )}
            </ScrollArea>
          </Flex>
        </Card>
      </Flex>
      <Space h="lg" />

      <ActionIcon
        variant="filled"
        color="pink"
        radius="xl"
        size="xl"
        style={{
          position: "fixed",
          bottom: "50px",
          right: "50px",
        }}
        onClick={open}
      >
        <IconPlus size={24} color="white" />
      </ActionIcon>

      <HolidayModal
        assign={false}
        opened={opened}
        close={close}
        refetch={refetch}
        date={new Date()}
      />
    </Flex>
  );
};

export default Holiday;
