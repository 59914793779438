import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { THolidayValues } from "../../../form/initial-value/addHoliday.value";


const AddHoliday = async (data: THolidayValues) => {
  const response: TServerResponse = await request({
    url: apiUrls.ADDHOLIDAY,
    method: "POST",
    data: data,
  });
  return response;
};

export const useAddHolidayMutation = () => {
  return useMutation(AddHoliday);
};
