import React from "react";
import {
  HoverCard,
  Center,
  Box,
  Group,
  Text,
  Indicator,
  Divider,
} from "@mantine/core";
import ThemeButton from "../../../component/button/ThemeButton";
import HolidayModal from "../modal/HolidayModal";
import { IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import classes from "./RenderDay.module.css";
import { checkWeekend } from "../../../utils/holiday/checkWeekend";
import { checkHoliday } from "../../../utils/holiday/checkHoliday";
import moment from "moment";

const RenderDay = (date: Date, allHoliday: [], refetch: () => void) => {
  const [opened, { open, close }] = useDisclosure(false);
  const day = date.getDate();
  const isWeekend = checkWeekend(date);
  const {
    category,
    holidayDate: isHoliday,
    name,
  } = checkHoliday(date, allHoliday);

  return (
    <>
      <HolidayModal
        assign={true}
        opened={opened}
        close={close}
        date={date}
        refetch={refetch}
      />

      <HoverCard radius="md" shadow="md" openDelay={500} withArrow zIndex={0}>
        <HoverCard.Target>
          <div
            className={classes.div}
            style={{
              color: !isWeekend && !isHoliday ? "black" : "red",
            }}
          >
            <Center>{day}</Center>
          </div>
        </HoverCard.Target>
        {isHoliday || isWeekend || date > new Date() ? (
          <HoverCard.Dropdown>
            {!isWeekend && !isHoliday ? (
              <Box onClick={open}>
                <ThemeButton
                  title="Add Holiday"
                  mr={15}
                  leftIcon={<IconPlus size={20} />}
                />
              </Box>
            ) : (
              <>
                <Group
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text fz="md" fw={600} color="dark">
                    {moment(date).format("DD-MMMM-YYYY")}
                  </Text>
                  <Box>
                    <Center>
                      <Indicator
                        inline
                        size={8}
                        position="middle-start"
                        color={new Date(date) > new Date() ? "yellow" : "red"}
                      >
                        <Text ml={10} size="xs" weight={500}>
                          {new Date(date) > new Date()
                            ? "Upcoming"
                            : "Previous"}
                        </Text>
                      </Indicator>
                    </Center>
                  </Box>
                </Group>
                <Divider my="sm" />
                <Group
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text fz="sm" fw={400} c="dimmed">
                    Category
                  </Text>

                  <Text ml={10} size="xs" weight={500} tt={"capitalize"}>
                    {category}
                  </Text>
                </Group>
                <Group
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text fz="sm" fw={400} c="dimmed">
                    Name
                  </Text>

                  <Text ml={10} size="xs" weight={500}>
                    {name}
                  </Text>
                </Group>
              </>
            )}
          </HoverCard.Dropdown>
        ) : null}
      </HoverCard>
    </>
  );
};

export default RenderDay;
