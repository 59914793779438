import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { useQuery } from "react-query";

const getAllHoliday = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.GET_ALL_HOLIDAY,
    method: "GET",
  });
  return response;
};

export const useGetAllHoliday = () => {
  return useQuery(["admin", "all-holiday"], () => getAllHoliday());
};
